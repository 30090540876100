/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.7.0/dist/jquery.min.js
 * - /npm/aos@3.0.0-beta.6/dist/aos.min.js
 * - /npm/bootstrap@5.2.0-beta1/dist/js/bootstrap.bundle.min.js
 * - /npm/spotlight.js@0.7.8/dist/spotlight.bundle.min.js
 * - /npm/vanilla-lazyload@17.8.4/dist/lazyload.min.js
 * - /npm/justifiedGallery@3.8.1/dist/js/jquery.justifiedGallery.min.js
 * - /npm/swiper@8.4.7/swiper-bundle.min.js
 * - /gh/mcstudios/glightbox@3.3.0/dist/js/glightbox.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
